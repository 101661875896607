<template>
  <div :class="$style.root">
    <SectionCard label="Оценка проведения инспекции">
      <div class="mb-2">
        <VRating color="primary" readonly :value="ratingValue" />
      </div>
      <Comment v-if="comment" :date="date" :author="author" :message="comment" />
    </SectionCard>
  </div>
</template>

<script>
import { toNumber } from 'lodash-es';
import Comment from '@/components/general/Comment/Comment.vue';
import SectionCard from '@/components/inspect/SectionCard/SectionCard.vue';

export default {
  name: 'InspectionFeedback',
  components: {
    Comment,
    SectionCard,
  },
  props: {
    inspector: { type: String },
    mark: { type: String, default: '0' },
    author: { type: String },
    date: { type: String },
    comment: { type: String },
  },
  computed: {
    ratingValue() {
      return toNumber(this.mark);
    }
  }
}
</script>

<style module lang='scss'>
.root {}
</style>
