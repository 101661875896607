<template>
  <SectionCard label="Отчётность по результату инспекции" v-if="isReviewerHasAccess || isInspector || isInitiator">
    <AppReportDetail
        v-if="create"
        @submit="onCreate"
        @reset="create = false"
    />
    <template v-if="!create">
      <VDataTable
          show-expand
          single-expand
          sortable
          must-sort
          sort-by="id"
          :sort-desc="false"
          :expanded.sync="expanded"
          :item-class="getClassByRow"
          :loading="pending"
          :headers="[
              { text: 'ID', value: 'id', sortable: true },
              { text: 'Инспектор', value: 'inspector', sortable: true },
              { text: 'Дата отчёта', value: 'name', sortable: false },
              { text: 'Дата изменения', value: 'dateEdit', sortable: false},
              { text: 'Отклонения по срокам', value: 'hasTimeDeviation', sortable: false },
              { text: 'Отклонения по качеству', value: 'hasQualityDeviation', sortable: false },
              { text: 'Файлы', value: 'files.length', sortable: false },
              { text: 'Комментарии', value: 'comment', sortable: false },
              { text: '', value: 'data-table-expand' },
            ]"
          :items="list.items"
          @click:row="onClickRow"
      >
        <template v-slot:item.hasTimeDeviation="{ item }">{{ item.hasTimeDeviation ? 'Да' : 'Нет'}}</template>
        <template v-slot:item.hasQualityDeviation="{ item }">{{ item.hasQualityDeviation ? 'Да' : 'Нет'}}</template>
        <template v-slot:item.inspector="{ item }">{{ item.inspector ? item.inspector.name : '' }}</template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="py-5 my-5">
              <AppReportDetail
                  :values="item"
                  :readonly="!canAddCreateReportControl(item)"
                  @reset="expanded  = []"
                  @submit="onEdit"
              />
            </div>
          </td>
        </template>
      </VDataTable>
      <div v-if="canAddReports" class="mt-2">
        <VBtn color="primary" class="mr-2" depressed @click="create = true">Добавить отчет</VBtn>
      </div>
    </template>
  </SectionCard>
</template>

<script>
import {debounce, get, find, toString} from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import AppReportDetail from '@/components/inspect/AppReportDetail/AppReportDetail';
import SectionCard from '@/components/inspect/SectionCard/SectionCard';
import { APPLICATION_STATUSES, USER_TYPES } from '@/store/inspect/enums';
export default {
  name: 'AppReportView',
  components: {
    SectionCard,
    AppReportDetail,
  },
  props: {
    state: { type: String },
    application: { type: String },
  },
  data() {
    return {
      create: false,
      expanded: [],
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      pending: 'inspect/pending',
      list: 'inspect/reports',
      current: 'inspect/application',
      user: 'user/current',
    }),
    isInspector() {
      return this.hasRole([get(USER_TYPES, 'INSPECTOR.value', 'INSPECTOR')], 'inspect');
    },
    isApproved() {
      return get(APPLICATION_STATUSES, 'APPROVED.value') === this.state;
    },
    isProlongation() {
      return get(APPLICATION_STATUSES, 'PROLONGATION.value') === this.state;
    },
    authorAsInspector() {
      return toString(get(this.current, 'values.inspector.values.0')) === toString(get(this.current, 'info.authorId'));
    },
    isInitiator() {
      return this.hasRole([get(USER_TYPES, 'INITIATOR.value', 'INITIATOR')], 'inspect');
    },
    isReviewerHasAccess() {
      const isReviewer = this.hasRole([get(USER_TYPES, 'REVIEWER.value', 'REVIEWER')], 'inspect');
      const listCount = get(this.list, 'count', 0);
      return isReviewer && listCount > 0;
    },
    canAddReports() {
      return (this.isInspector || this.authorAsInspector) && (this.isApproved || this.isProlongation) && toString(get(this.current, 'values.inspector.values.0')) === toString(get(this.user, 'id'));
    },
  },
  methods: {
    ...mapActions({
      fetchReportList: 'inspect/fetchReportList',
      createReport: 'inspect/createReport',
      updateReport: 'inspect/updateReport',
    }),
    onClickRow(item) {
      if (find(this.expanded, { id: item.id})) {
        this.expanded = [];
      } else {
        this.expanded = [item];
      }
    },
    onCreate({ name, comment, hasTimeDeviation, hasQualityDeviation, files }) {
      const application = this.application;
      this.createReport({ application, name, comment, hasTimeDeviation, hasQualityDeviation, files }).then(() => {
        this.create = false;
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Отчет успешно создан',
        });
        this.onUpdate();
      });
    },
    onEdit({ id, name, comment, hasTimeDeviation, hasQualityDeviation, files }) {
      this.updateReport({ id, name, comment, hasTimeDeviation, hasQualityDeviation, files }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Отчет успешно изменен',
        });
        this.expanded = [];
        this.onUpdate();
      });
    },
    onUpdate: debounce(function() {
      const application = this.application;
      const page = 1;
      const size = 100;
      const filter = { 'applicationId': application }
      if (application) this.fetchReportList({ page, size, filter });
    }, 500),
    getClassByRow({ hasTimeDeviation, hasQualityDeviation }) {
      if (hasTimeDeviation || hasQualityDeviation) return 'error lighten-4';
      return '';
    },
    canAddCreateReportControl({ inspector }) {
      const currentUserId = toString(get(this.user, 'id'));
      return this.canAddReports && (inspector && toString(inspector.id) === currentUserId);
    },
  },
  watch: {
    application: {
      immediate: true,
      handler(id) {
        if (id) this.onUpdate();
      }
    }
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
