var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VForm',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.isMaster)?_c('VDataTable',{attrs:{"fixed-header":true,"height":"300px","show-select":"","single-select":true,"headers":[
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Имя', value: 'name', sortable: false },
        { text: 'Почта', value: 'email', sortable: false },
        { text: 'В работе', value: 'work', sortable: false },
        { text: 'Всего инспекций', value: 'inspections', sortable: false },
        { text: 'Кол-во отзывов', value: 'rejects', sortable: false },
      ],"items":_vm.list.items,"count":_vm.list.count,"page":_vm.page,"size":_vm.size,"loading":_vm.pending,"item-class":({ id }) => id === _vm.value ? 'success lighten-3 font-weight-bold' : null},model:{value:(_vm.inspector),callback:function ($$v) {_vm.inspector=$$v},expression:"inspector"}}):_vm._e(),_c('VTextarea',{attrs:{"label":_vm.label,"rows":"3","outlined":"","auto-grow":"","hide-details":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('div',{staticClass:"mt-3"},[_c('VBtn',{staticClass:"mr-3",attrs:{"color":"primary","depressed":"","disabled":_vm.disabled},on:{"click":_vm.onSubmit}},[_vm._v("Сохранить")]),(!_vm.disabled)?_c('VBtn',{staticClass:"mr-3",attrs:{"color":"secondary","depressed":""},on:{"click":_vm.onReset}},[_vm._v("Отмена")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }