<template>
  <VForm @submit.prevent="onSubmit">
    <VDataTable
      v-if="isMaster"
      :fixed-header="true"
      height="300px"
      show-select
      :single-select="true"
      v-model="inspector"
      :headers="[
          { text: 'ID', value: 'id', sortable: false },
          { text: 'Имя', value: 'name', sortable: false },
          { text: 'Почта', value: 'email', sortable: false },
          { text: 'В работе', value: 'work', sortable: false },
          { text: 'Всего инспекций', value: 'inspections', sortable: false },
          { text: 'Кол-во отзывов', value: 'rejects', sortable: false },
        ]"
      :items="list.items"
      :count="list.count"
      :page="page"
      :size="size"
      :loading="pending"
      :item-class="({ id }) => id === value ? 'success lighten-3 font-weight-bold' : null"
    />
    <VTextarea v-model="message" :label="label" rows="3" outlined auto-grow hide-details />
    <div class="mt-3">
      <VBtn color="primary" depressed class="mr-3" :disabled="disabled" @click="onSubmit">Сохранить</VBtn>
      <VBtn v-if="!disabled" color="secondary" depressed class="mr-3" @click="onReset">Отмена</VBtn>
    </div>
  </VForm>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { debounce, first, get, isEmpty, omitBy } from 'lodash-es';

export default {
  name: 'InspectorDecisionForm',
  props: {
    value: { type: String },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    label: { type: String, default: () => 'Комментарий' },
  },
  data() {
    return {
      message: '',
      inspector: [],
    }
  },
  computed: {
    ...mapGetters({
      pending: 'inspect/pending',
      list: 'inspect/inspectors',
      user: 'user/current',
    }),
    isMaster() {
      return isEmpty(get(this.user, ['role', 'inspect', 'master']));
    },
    disabled() {
      return isEmpty(this.message) || (this.isMaster && isEmpty(this.inspector));
    },
  },
  methods: {
    ...mapActions({
      fetchInspectorList: 'inspect/fetchInspectorList',
      inviteInspector: 'inspect/inviteInspector',
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchInspectorList({ page, size, filter });
    }, 500),
    onSubmit() {
      const comment = this.message;
      const inspector = get(first(this.inspector), 'id');
      this.$emit('submit', omitBy({ inspector, comment }, isEmpty));
      this.message = '';
    },
    onReset() {
      this.message = '';
      this.inspector = [];
    },
  },
  mounted() {
    this.onUpdateState();
  }
}
</script>