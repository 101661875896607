var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isReviewerHasAccess || _vm.isInspector || _vm.isInitiator)?_c('SectionCard',{attrs:{"label":"Отчётность по результату инспекции"}},[(_vm.create)?_c('AppReportDetail',{on:{"submit":_vm.onCreate,"reset":function($event){_vm.create = false}}}):_vm._e(),(!_vm.create)?[_c('VDataTable',{attrs:{"show-expand":"","single-expand":"","sortable":"","must-sort":"","sort-by":"id","sort-desc":false,"expanded":_vm.expanded,"item-class":_vm.getClassByRow,"loading":_vm.pending,"headers":[
            { text: 'ID', value: 'id', sortable: true },
            { text: 'Инспектор', value: 'inspector', sortable: true },
            { text: 'Дата отчёта', value: 'name', sortable: false },
            { text: 'Дата изменения', value: 'dateEdit', sortable: false},
            { text: 'Отклонения по срокам', value: 'hasTimeDeviation', sortable: false },
            { text: 'Отклонения по качеству', value: 'hasQualityDeviation', sortable: false },
            { text: 'Файлы', value: 'files.length', sortable: false },
            { text: 'Комментарии', value: 'comment', sortable: false },
            { text: '', value: 'data-table-expand' },
          ],"items":_vm.list.items},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.hasTimeDeviation",fn:function({ item }){return [_vm._v(_vm._s(item.hasTimeDeviation ? 'Да' : 'Нет'))]}},{key:"item.hasQualityDeviation",fn:function({ item }){return [_vm._v(_vm._s(item.hasQualityDeviation ? 'Да' : 'Нет'))]}},{key:"item.inspector",fn:function({ item }){return [_vm._v(_vm._s(item.inspector ? item.inspector.name : ''))]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"py-5 my-5"},[_c('AppReportDetail',{attrs:{"values":item,"readonly":!_vm.canAddCreateReportControl(item)},on:{"reset":function($event){_vm.expanded  = []},"submit":_vm.onEdit}})],1)])]}}],null,false,3179788107)}),(_vm.canAddReports)?_c('div',{staticClass:"mt-2"},[_c('VBtn',{staticClass:"mr-2",attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.create = true}}},[_vm._v("Добавить отчет")])],1):_vm._e()]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }