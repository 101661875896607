<template>
  <VBtn fab small depressed color="light" @click="$emit('click')">
    <VIcon small dark>mdi-message-outline</VIcon>
  </VBtn>
</template>

<script>
export default {
  name: 'CommentBtn'
}
</script>

<style module lang="scss">

</style>
