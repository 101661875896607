<template>
  <VDialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{}">
      <VBtn color="warning" class="mr-2" depressed @click="onOpen()">Отозвать исполнителя</VBtn>
    </template>
    <VCard tile>
      <VToolbar flat dark color="primary">
        <VToolbarTitle class="px-2">Укажите причину отзыва исполнителя</VToolbarTitle>
        <VSpacer/>
        <VBtn icon dark @click="onClose">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VCardText class="pt-5">
        <RevokeForm v-bind="{inspector, slavesCount}" @submit="onSubmit" />
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import RevokeForm from '@/components/inspect/ApplicationDetail/components/RevokeForm';
export default {
  name: 'InspectorRevoke',
  components: {
    RevokeForm,
  },
  props: {
    inspector: { type: Object },
    slavesCount: { type: Number },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    onOpen() {
      this.dialog = true;
    },
    onClose() {
      this.dialog = null;
    },
    onSubmit({ name, comment, action, files }) {
      this.dialog = false;
      this.$emit('submit', { name, comment, action, files });
    }
  }
}
</script>

<style module lang="scss">

</style>
