<template>
  <VDialog v-model="dialog" max-width="900">
    <template v-slot:activator="{ on }">
      <VBtn color="primary" class="mr-2" depressed v-on="on" @click="onOpen(true)">Участвовать</VBtn>
      <VBtn color="secondary" class="mr-2" depressed v-on="on" @click="onOpen(false)">Отказаться от участия</VBtn>
    </template>
    <VCard tile>
      <VToolbar flat dark color="primary">
        <VToolbarTitle class="px-2">Выбор инспектора</VToolbarTitle>
        <VSpacer/>
        <VBtn icon dark @click="onClose">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VCardText class="pt-5">
        <InspectorDecisionForm @submit="onSubmit" />
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InspectorDecisionForm from "@/components/inspect/ApplicationDetail/components/InspectorDecisionForm.vue";

export default {
  name: 'InspectorDecision',
  components: {
    InspectorDecisionForm,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
  },
  data() {
    return {
      dialog: false,
      decision: null,
    }
  },
  computed: {
    ...mapGetters({
      pending: 'inspect/pending',
      list: 'inspect/inspectors',
    }),
  },
  methods: {
    ...mapActions({
      fetchInspectorList: 'inspect/fetchInspectorList',
      inviteInspector: 'inspect/inviteInspector'
    }),
    onOpen(decision) {
      this.dialog = true;
      this.decision = decision;
    },
    onClose() {
      this.dialog = null;
    },
    onSubmit({ comment, inspector }) {
      this.dialog = false;
      const decision = this.decision;
      this.$emit('submit', { decision, comment, inspector });
    },
  },
}
</script>

<style module lang="scss">

</style>
