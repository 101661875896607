<template>
  <SectionCard label="Информация о заявке">
    <VRow>
      <VCol v-for="{label, value, options, hint} in information" cols="12" md="6" :key="label">
        <StaticField :label="label" :value="value" :items="options" />
        <small v-if="!!hint" class="error--text" v-text="hint" />
      </VCol>
    </VRow>
    <VRow>
      <VCol v-for="{label, value, options} in persons" cols="12" md="3" :key="label">
        <StaticField :label="label" :value="value" :items="options" />
      </VCol>
    </VRow>
  </SectionCard>
</template>

<script>
import {get, find, pick, values, compact, join} from 'lodash-es';
import StaticField from '@/components/general/StaticField/StaticField';
import SectionCard from '@/components/inspect/SectionCard/SectionCard';
import { APPLICATION_STATUSES } from '@/store/inspect/enums';

export default {
  name: 'ApplicationInfo',
  components: {
    StaticField,
    SectionCard,
  },
  props: {
    current: { type: Object }
  },
  computed: {
    isInternalInspection() {
      return !!~get(this.current, ['values', 'isInternalInspection', 'values'], []).indexOf(true);
    },
    information() {
      const status = get(this.current, 'info.status', '');
      const date = get(this.current, 'info.date', '');
      return [
        {
          label: 'Статус заявки',
          value: get(status, 'name'),
          hint: get(status, 'value') === APPLICATION_STATUSES.DRAFT.value && 'Неактивные черновики удаляются через 10 дней'
        },
        {
          label: 'Дата заявки',
          value: date,
          hint: ''
        },
      ]
    },
    persons() {
      const author = get(this.current, 'info.author', '');
      const workshopReviewers = get(this.current, ['values', 'workshopReviewer', 'options']);
      const workshopReviewer = get(this.current, ['values', 'workshopReviewer', 'values', 0]);
      const reviewers = get(this.current, ['values', 'reviewer', 'options']);
      const reviewer = get(this.current, ['values', 'reviewer', 'values', 0]);
      const inspectors = get(this.current, ['values', 'inspector', 'options']);
      const inspectorId = get(this.current, ['values', 'inspector', 'values', 0]);
      const inspector = find(inspectors, { id: inspectorId });
      const inspectorName = join(compact(values(pick(inspector, ['name', 'userName']))), ' ') || '- не выбран -';
      return this.isInternalInspection ? [
        {label: 'Заявитель', value: author},
        {label: 'Согласующий', value:  get(find(workshopReviewers, { value: workshopReviewer }), 'text', '- не выбран -') },
        {label: 'Инспектор', value:  inspectorName},
      ]: [
        {label: 'Заявитель', value: author},
        {label: 'Согласующий', value:  get(find(workshopReviewers, { value: workshopReviewer }), 'text', '- не выбран -') },
        {label: 'Администратор заявки', value:  get(find(reviewers, { value: reviewer }), 'text', '- не выбран -') },
        {label: 'Инспектор', value:  inspectorName},
      ]
    }
  }
}
</script>

<style module lang="scss">

</style>
