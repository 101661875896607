<template>
  <div>
    <div v-if="!items.length">
      Нет данных
    </div>
    <VTimeline
        v-else
        dense
        clipped
    >
      <VTimelineItem
          v-for="(item, index) in items"
          :key="item.id"
          :color="index > 0 ? 'grey' : 'primary'"
          class="mb-4"
          small
      >
        <div>
          <strong>{{ item.author }}</strong> <small>{{ item.date }}</small>
        </div>
        <div>
          <span v-if="!!item.old.length"><s>{{ item.old }}</s> <VIcon v-text="'mdi-arrow-right-thin'" /></span> {{ item.new }}
        </div>
        <div v-if="item.comment">
          <i>Комментарий:</i>
          {{ item.comment }}
        </div>
      </VTimelineItem>
    </VTimeline>
  </div>
</template>

<script>

export default {
  name: 'TimeLine',
  props: {
    items: { type: Array, default: () => [] }
  },
}
</script>

<style scoped>

</style>
