<template>
  <div :class="$style.root">
    <VTextarea :label="label" :value="text" rows="1" auto-grow dense outlined readonly hideDetails="auto" />
  </div>
</template>

<script>
import { get, find, toString } from 'lodash-es';
export default {
  name: 'StaticField',
  props: {
    label: { type: String },
    value: { type: [String, Number] },
    items: { type: Array, default: () => [] },
  },
  computed: {
    text: function() {
      return get(find(this.items, ({ value }) => toString(value) === toString(this.value)), 'text', this.value) || ' ';
    }
  }
}
</script>
<style module lang="scss">
.root {
  pointer-events: none;
  :global(.v-text-field--outlined fieldset) {
    border-style: dashed;
    border-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
