<template>
  <div>
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}} #{{id}}</h1>
    <ApplicationInfo v-if="current" :current="current" />
    <ApplicationDetail
        v-if="current"
        :id="id"
        :state="state"
        :count="count"
        :values="current.values"
        :info="current.info"
        :inspector-slaves-count="current.inspectorSlavesCount"
    />
    <VDialog :value="$route.name === 'inspect/ApplicationCommentsView'"  max-width="900" scrollable persistent>
      <ApplicationCommentsView :application="id" :code="code" :state="state" />
    </VDialog>
    <VDialog :value="$route.name === 'inspect/AppReportCommentsView'" max-width="900" scrollable persistent>
      <AppReportCommentsView :application="id" @close="onClose" />
    </VDialog>
    <AppReportView v-if="(isInspector || isInitiator || isReviewer) && !isDraft" :application="id" :state="state" />
    <InspectionFeedback
        v-if="(isInitiator || isReviewer) && isFinished && !!feedback"
        v-bind="{...feedback}"
    />
    <ApplicationTimelineView v-if="!isInspector" :id="id" :state="state" />
  </div>
</template>

<script>
import { get } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import ApplicationInfo from '@/components/inspect/ApplicationInfo/ApplicationInfo';
import ApplicationDetail from '@/components/inspect/ApplicationDetail/ApplicationDetail';
import InspectionFeedback from '@/views/inspect/ApplicationDetailView/components/InspectionFeedback';
import AppReportView from '@/views/inspect/AppReportView/AppReportView';
import ApplicationCommentsView from '@/views/inspect/ApplicationCommentsView/ApplicationCommentsView';
import AppReportCommentsView from '@/views/inspect/AppReportCommentsView/AppReportCommentsView';
import ApplicationTimelineView from '@/views/inspect/ApplicationTimelineView/ApplicationTimelineView';
import { APPLICATION_STATUSES, USER_TYPES } from '@/store/inspect/enums';

export default {
  name: 'ApplicationDetailView',
  components: {
    AppReportView,
    ApplicationInfo,
    ApplicationDetail,
    InspectionFeedback,
    ApplicationCommentsView,
    AppReportCommentsView,
    ApplicationTimelineView,
  },
  props: {
    id: { type: String },
    code: { type: String },
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      pending: 'inspect/pending',
      current: 'inspect/application',
    }),
    state() {
      return get(this.current, 'state');
    },
    feedback() {
      return get(this.current, 'feedback');
    },
    count() {
      return get(this.current, 'messages.active', 0);
    },
    isInitiator() {
      return this.hasRole([get(USER_TYPES, 'INITIATOR.value', 'INITIATOR')], 'inspect');
    },
    isInspector() {
      return this.hasRole([get(USER_TYPES, 'INSPECTOR.value', 'INSPECTOR')], 'inspect');
    },
    isReviewer() {
      return this.hasRole([get(USER_TYPES, 'REVIEWER.value', 'REVIEWER')], 'inspect');
    },
    isDraft() {
      return get(APPLICATION_STATUSES, 'DRAFT.value') === this.state;
    },
    isFinished() {
      return get(APPLICATION_STATUSES, 'FINISH.value') === this.state;
    },
  },
  methods: {
    ...mapActions({
      fetchApplicationDetail: 'inspect/fetchApplicationDetail',
      viewedChatList: 'inspect/viewedChatList',
    }),
    onClose() {
      const id = this.id;
      this.viewedChatList({ id }).then(() => {
        return this.fetchApplicationDetail({ id });
      });
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(id) {
        if (id) this.fetchApplicationDetail({ id });
      }
    }
  }
}
</script>

<style module lang="scss">

</style>
