<template>
<div :class="$style.root">
  <VForm v-model="valid" lazy-validation  ref="form" @submit.prevent="onSave">
    <SectionCard label="Информация о планируемой инспекции">
      <div :class="$style.item">
        <template v-for="code in hiddenForRole(['company', 'workshop'])">
          <ApplicationValues
              :multiple="!!~['workshop', 'productCategory'].indexOf(code)"
              :class="getClassByValue(code)"
              :code="code"
              :application="id"
              :label="fields[code]['label']"
              :readonly="fields[code]['readonly']"
              :options="options[code] || fields[code]['options']"
              :values="fields[code]['values']"
              :rules="code !== 'hasApprovingForPersonalInfo' ? rules.required : []"
              :show-comments="showCommentBtn(code)"
              :hint="fields[code]['hint']"
              :key="code"
              @update:values="(values) => fields[code]['values'] = values"
          />
        </template>
        <template v-if="showWorkshopAnotherComment">
          <ApplicationValues
              v-for="code in hiddenForRole(['workshopAnotherComment'])"
              :class="getClassByValue(code)"
              :code="code"
              :application="id"
              :label="fields[code]['label']"
              :readonly="fields[code]['readonly']"
              :options="options[code] || fields[code]['options']"
              :values="fields[code]['values']"
              :rules="rules.required"
              :show-comments="showCommentBtn(code)"
              :hint="fields[code]['hint']"
              :key="code"
              @update:values="(values) => fields[code]['values'] = values"
          />
        </template>
        <template v-for="code in hiddenForRole(['productSupplyFio', 'productSupplyName', 'productSupplyInn', 'productSupplyPhone', 'productSupplyEmail', 'manufacturerName', 'inspectionAddress', 'hasApprovingForPersonalInfo', 'productCategory'])">
          <ApplicationValues
              :multiple="!!~['workshop', 'productCategory'].indexOf(code)"
              :class="getClassByValue(code)"
              :code="code"
              :application="id"
              :label="fields[code]['label']"
              :readonly="fields[code]['readonly']"
              :options="options[code] || fields[code]['options']"
              :values="fields[code]['values']"
              :rules="code !== 'hasApprovingForPersonalInfo' ? rules.required : []"
              :show-comments="showCommentBtn(code)"
              :hint="fields[code]['hint']"
              :key="code"
              @update:values="(values) => fields[code]['values'] = values"
          />
        </template>
        <template v-if="showProductCategoryAnotherComment">
          <ApplicationValues
              v-for="code in hiddenForRole(['productCategoryAnotherComment'])"
              :class="getClassByValue(code)"
              :code="code"
              :application="id"
              :label="fields[code]['label']"
              :readonly="fields[code]['readonly']"
              :options="options[code] || fields[code]['options']"
              :values="fields[code]['values']"
              :rules="rules.required"
              :show-comments="showCommentBtn(code)"
              :hint="fields[code]['hint']"
              :key="code"
              @update:values="(values) => fields[code]['values'] = values"
          />
        </template>
      </div>
      <div v-if="showProducts" :class="$style.item">
        <div :class="[$style.value, getClassByValue('products')]">
          <VRow>
            <VCol cols="12" md="3" class="pb-0">
              <div :class="$style.title">Товары</div>
            </VCol>
            <VCol v-if="fields['products']['readonly'] && !fields['products']['values'].length" cols="12" md="9">
              <CommentBtn v-if="showCommentBtn('products')" :class="$style.control" @click="toComment('products')" />
              <StaticField v-if="fields['products']['readonly'] && !fields['products']['values'].length" value="Отсутствуют" />
            </VCol>
            <VCol cols="12" class="pt-0" v-else>
              <div v-for="(items, index) in fields['products']['values']" :class="[$style.product, getClassByValue(items.id)]" :key="index">
                <div :class="$style.left">
                  <div v-if="!index" :class="$style.title">№</div>
                  <div :class="$style.index">{{ index + 1}})</div>
                </div>
                <div :class="$style.right">
                  <CopyBtn v-if="!fields['products']['readonly']" @click="() => fields['products']['values'].push(Object.assign(JSON.parse(JSON.stringify(items)), { id: new Date().getTime().toString()}))" />
                  <RemoveBtn v-if="!fields['products']['readonly']" @click="() => fields['products']['values'].splice(index, 1)" />
                  <CommentBtn v-if="showCommentBtn(items.id)" :class="$style.control" @click="toComment(`product-${items.id}`)" />
                </div>
                <VRow no-gutters>
                  <VCol v-for="({key, label, rules}) in [
                    {key: 'name', label: 'Наименование', rules: rules.required},
                    {key: 'quantity', label: 'Количество', rules: rules.required},
                    {key: 'number', label: 'Заводской номер', rules: []},
                    {key: 'sapCode', label: 'Код SAP', rules: []},
                ]" cols="3" class="pr-2" :key="key">
                    <div v-if="!index" :class="$style.title">{{label}}</div>
                    <template v-if="!fields['products']['readonly'] || (isInspector && (isApproved || isProlongation) && key === 'number')">
                      <template v-if="diff && isInspector && (isApproved || isProlongation) && key === 'number'">
                        <VBtn icon color="primary" class="float-right" @click="onSave">
                          <VIcon>mdi-check</VIcon>
                        </VBtn>
                      </template>
                      <VTextarea v-model="items[key]" :rules="rules" outlined dense auto-grow rows="1" hideDetails="auto" />
                    </template>
                    <template v-else>
                      <StaticField :value="items[key]" :items="fields['products']['options']" />
                    </template>
                  </VCol>
                </VRow>
              </div>
              <div v-if="!fields['products']['readonly']" class="mt-2">
                <VBtn color="primary" depressed  @click="() => fields['products']['values'].push({ name: '', quantity: '1', number: '', sapCode: '', id: new Date().getTime().toString() })">Добавить</VBtn>
              </div>
            </VCol>
          </VRow>
        </div>
      </div>
      <div :class="$style.item">
        <div :class="[$style.value, getClassByValue('inspectionTypes')]">
          <VRow>
            <VCol cols="12" md="3">
              <div :class="$style.title" v-html="fields['inspectionTypes']['label']" />
            </VCol>
            <VCol v-if="fields['inspectionTypes']['readonly']" cols="12" md="9">
              <CommentBtn v-if="showCommentBtn('inspectionTypes')" :class="$style.control" @click="toComment('inspectionTypes')" />
              <template v-if="fields['inspectionTypes']['readonly']">
                <VRow dense>
                  <VCol v-for="value in fields['inspectionTypes']['values']" cols="12" :key="value">
                    <StaticField :value="value" :items="fields['inspectionTypes']['options']" />
                  </VCol>
                </VRow>
              </template>
            </VCol>
            <VCol v-else cols="12">
              <CommentBtn v-if="showCommentBtn('inspectionTypes')" :class="$style.control" @click="toComment('inspectionTypes')" />
              <VRow dense>
                <VCol v-for="option in fields['inspectionTypes']['options']" cols="12" md="6" :key="option.value">
                  <div style="width: 90%">
                    <VCheckbox
                        v-model="fields['inspectionTypes']['values']"
                        :rules="rules.required"
                        :label="option.text"
                        :value="option.value"
                        :readonly="fields['inspectionTypes']['readonly']"
                        :disabled="fields['inspectionTypes']['readonly'] && !~fields['inspectionTypes']['values'].indexOf(option.value)"
                        hideDetails
                        dense
                    />
                  </div>
                </VCol>
              </VRow>
            </VCol>
          </VRow>
        </div>
        <template v-if="showInspectionTypeAnotherComment">
          <ApplicationValues
              v-for="code in hiddenForRole(['inspectionTypeAnotherComment'])"
              :class="getClassByValue(code)"
              :code="code"
              :application="id"
              :label="fields[code]['label']"
              :readonly="fields[code]['readonly']"
              :options="options[code] || fields[code]['options']"
              :values="fields[code]['values']"
              :rules="rules.required"
              :show-comments="showCommentBtn(code)"
              :hint="fields[code]['hint']"
              :key="code"
              @update:values="(values) => fields[code]['values'] = values"
          />
        </template>
      </div>
      <div :class="$style.item">
        <div :class="[$style.value, getClassByValue('inspectionDate')]">
          <VRow>
            <VCol cols="12" md="3">
              <div :class="$style.title" v-html="fields['inspectionDate']['label']" />
            </VCol>
            <VCol cols="12" md="9">
              <div v-for="(items, index) in fields['inspectionDate']['values']" :key="index">
                <CommentBtn v-if="showCommentBtn('inspectionDate')" :class="$style.control" @click="toComment('inspectionDate')" />
                <VRow dense>
                  <VCol v-for="({ key, label }) in [{key: 'start', label: 'с'},  {key: 'end', label: 'по'}]" :key="key">
                    <template v-if="fields['inspectionDate']['readonly']">
                      <StaticField :label="label" :value="items[key]" :items="fields['inspectionDate']['options']" />
                    </template>
                    <template v-else>
                      <DateInput v-model="items[key]" :label="label" :rules="rules.required" outlined dense auto-grow rows="1" hideDetails="auto" />
                    </template>
                  </VCol>
                </VRow>
              </div>
            </VCol>
          </VRow>
        </div>
        <ApplicationValues
          v-for="code in hiddenForRole(['costItem'])"
          :key="code"
          :class="getClassByValue(code)"
          :application="id"
          :code="code"
          :label="fields[code]['label']"
          :readonly="fields[code]['readonly']"
          :options="options[code] || fields[code]['options']"
          :values="fields[code]['values']"
          :rules="rules.required"
          :show-comments="showCommentBtn(code)"
          :hint="!fields['company']['values'].length ? fields[code]['hint'] : ''"
          @update:values="(values) => fields[code]['values'] = values"
        />
        <template v-if="has(fields['expenceItemAdditionalData']['label'], fields['costItem']['values']['0'])">
          <ApplicationValues
            v-for="code in hiddenForRole(['expenceItemAdditionalData'])"
            :key="code"
            :class="getClassByValue(code)"
            :application="id"
            :code="code"
            :label="fields[code]['label'][fields['costItem']['values']['0']]"
            :readonly="fields[code]['readonly']"
            :options="options[code] || fields[code]['options']"
            :values="fields[code]['values']"
            :rules="rules.required"
            :show-comments="showCommentBtn(code)"
            :hint="!fields['company']['values'].length ? fields[code]['hint'] : ''"
            @update:values="(values) => fields[code]['values'] = values"
          />
        </template>
        <div v-for="key in hiddenForRole(['confirmationDocuments'])" :key="key" :class="[$style.value, getClassByValue(key)]">
          <VRow>
            <VCol cols="12" md="3">
              <div :class="$style.title" v-html="fields[key]['label']" />
            </VCol>
            <VCol cols="12" md="9">
              <CommentBtn v-if="showCommentBtn(key)" :class="$style.control" @click="toComment(key)" />
              <StaticField v-if="fields[key]['readonly'] && !fields[key]['values'].length" value="Отсутствует" />
              <FileFieldMultiple
                  v-else
                  :value="fields[key]['values'].map(({ id }) => ({ value: id }))"
                  :readonly="fields[key]['readonly']"
                  @input="(values) => fields[key]['values'] = values.map(({ value }) => ({ id: value }))"
              />
            </VCol>
          </VRow>
        </div>
        <div v-for="key in hiddenForRole(['confirmationDocument', 'contractSpecificationDocs', 'additionalDocs'])" :key="key" :class="[$style.value, getClassByValue(key)]">
          <VRow>
            <VCol cols="12" md="3">
              <div :class="$style.title" v-html="fields[key]['label']" />
            </VCol>
            <VCol cols="12" md="9">
              <CommentBtn v-if="showCommentBtn(key)" :class="$style.control" @click="toComment(key)" />
              <StaticField v-if="fields[key]['readonly'] && !fields[key]['values'].length" value="Отсутствует" />
              <FileFieldMultiple
                  v-else
                  :value="fields[key]['values'].map(({ id }) => ({ value: id }))"
                  :readonly="fields[key]['readonly']"
                  @input="(values) => fields[key]['values'] = values.map(({ value }) => ({ id: value }))"
              />
            </VCol>
          </VRow>
        </div>
        <ApplicationValues
            v-for="code in hiddenForRole(['additionalInfo', 'isInternalInspection'])"
            :class="getClassByValue(code)"
            :code="code"
            :application="id"
            :label="fields[code]['label']"
            :readonly="fields[code]['readonly']"
            :options="options[code] || fields[code]['options']"
            :values="fields[code]['values']"
            :rules="[]"
            :show-comments="showCommentBtn(code)"
            :hint="fields[code]['hint']"
            :key="code"
            @update:values="(values) => fields[code]['values'] = values"
        />
        <ApplicationValues
            v-for="code in hiddenForRole(isInternalInspection ? ['workshopReviewer', 'inspector'] : ['workshopReviewer', 'reviewer'])"
            :class="getClassByValue(code)"
            :code="code"
            :application="id"
            :label="fields[code]['label']"
            :readonly="fields[code]['readonly']"
            :options="options[code] || fields[code]['options']"
            :values="fields[code]['values']"
            :rules="rules.required"
            :show-comments="showCommentBtn(code)"
            :hint="fields[code]['hint']"
            :key="code"
            @update:values="(values) => fields[code]['values'] = values"
        />
      </div>
    </SectionCard>
    <SectionCard v-if="!isDraft && !isInternalInspection && (isReviewer || isWorkshopReviewer || isInitiator)" label="Выбор инспекционной компании">
      <VRow v-if="fields['inspector']['values'][0]">
        <VCol cols="12" md="3">
          <div :class="$style.title" v-html="fields['inspector']['label']" />
        </VCol>
        <VCol cols="12" md="9">
          <StaticField :value="fields['inspector']['values'][0] || 'Не назначен'" :items="fields['inspector']['options'].map(({ id, name, userName }) => ({ value: id, text: [name, userName].join(' ') }))" />
          <div v-if="isReviewer && (isApproving || isAwaitReplacementInspector || isRework)" class="mt-2">
            <VBtn color="light" depressed :disabled="pending" @click="fields['inspector']['values'] = []">
              Выбрать другого инспектора
            </VBtn>
          </div>
        </VCol>
      </VRow>
      <VRow v-if="!fields['inspector']['values'][0] || !(isApproving || isAwaitReplacementInspector || isRework) && isReviewer">
        <VCol cols="12">
          <VDataTable
              :show-select="isReviewer && isMyApplication && (isApproving || isAwaitReplacementInspector || isRework)"
              hide-default-footer
              v-model="inspectors"
              :headers="[
              { text: 'ID', value: 'id', sortable: false },
              { text: 'Компания', value: 'name', sortable: false },
              { text: 'Дата приглашения', value: 'dateCreate', sortable: false },
              { text: 'Дата ответа', value: 'dateFinish', sortable: false },
              { text: 'Статус приглашения', value: 'status', sortable: false },
              { text: 'Комментарии', value: 'comment', sortable: false },
            ]"
              :items="inspectorOptions"
              :item-class="getInspectorClassByRow"
          />
        </VCol>
      </VRow>
      <VRow v-if="!fields['inspector']['values'][0] || !(isApproving || isAwaitReplacementInspector || isRework) && isReviewer">
        <div v-if="isReviewer && isMyApplication && (isApproving || isAwaitReplacementInspector || isRework)" class="mt-2">
          <template v-if="inspectors.length">
            <template v-if="inspectors.length === 1">
              <VBtn color="primary" class="mr-2" depressed @click="fields['inspector']['values'].push(inspectors[0]['id'])">Назначить инспектора</VBtn>
              <template v-if="!isAwaitReplacementSlaveInspector && ['WITHOUT_ANSWER', 'AGREED'].includes(inspectors[0]['statusCode'])">
                <CancelInvite @submit="({ comment }) => onCancelInvite({ comment, inspectors: [inspectors[0]['id']] })">
                  {{ inspectors[0]['name'] }}
                </CancelInvite>
              </template>
            </template>
            <VBtn v-if="!isAwaitReplacementSlaveInspector" color="secondary" class="mr-2" depressed @click="onSendInvite">Отправить приглашение</VBtn>
          </template>
          <template v-else>
            <VBtn color="primary" class="mr-2" depressed @click="$router.push({ name: 'inspect/ApplicationInspectorBindingListView', params: { application: id } })">Добавить инспектора</VBtn>
          </template>
        </div>
      </VRow>
    </SectionCard>
  </VForm>
  <VFooter app>
    <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" :class="$style.progress" />
    <div class="py-3 grow">
      <VBtn class="mr-6" outlined depressed @click="$router.push({ name: 'inspect/ApplicationListView' })">
        <VIcon>mdi-arrow-left</VIcon>
        Назад к списку
      </VBtn>
      <template v-if="isMyApplication">
        <template v-if="diff">
          <VBtn color="primary" class="mr-2" depressed :disabled="pending" @click="onSave">Сохранить</VBtn>
          <VBtn color="secondary" class="mr-2" depressed :disabled="pending" @click="onReset">Сбросить</VBtn>
        </template>
        <template v-else>
          <template v-if="isInitiator">
            <template v-if="isWorkshopRework || isDraft">
                <VBtn v-if="!diff" color="primary" class="mr-2" depressed :disabled="pending || hasActualComments || !valid" @click="onWorkshopApprove">Отправить на согласование</VBtn>
            </template>
            <template v-if="isRework">
                <VBtn v-if="!diff" color="primary" class="mr-2" depressed :disabled="pending || hasActualComments || !valid" @click="onToApprove">Отправить на согласование</VBtn>
            </template>
            <template v-if="isDraft">
              <VBtn color="error" class="mr-2" depressed :disabled="pending" @click="onDelete">Удалить черновик</VBtn>
            </template>
            <template v-if="isFinishRequest || isApproved || isProlongation || (!isDraft && !isFinish && !isFinishByReviewer && !isFinishByInitiator && !isFinishByInspectorFailed && !isRevokeRequest && !isSlaveRevokeRequest)">
              <ApplicationFinished @submit="onFinish" :hidden-rating="!isFinishRequest && !isApproved && !isProlongation">
                <template v-if="!isApproved && !isFinishRequest" v-slot:title>Укажите причину принудительного закрытия заявки</template>
              </ApplicationFinished>
            </template>
            <template v-if="isFinishRequest">
              <ApplicationProlog @submit="onProlong" />
            </template>
            <template v-if="inspectorOptions[0] && isApproved && !isInternalInspection">
              <InspectorRevoke :inspector="inspectorOptions[0]" :slaves-count="inspectorSlavesCount" @submit="onRevoke" />
            </template>
          </template>
          <template v-if="isWorkshopReviewer">
            <template v-if="isApprovingWorkshop">
              <VBtn color="success" class="mr-2" depressed :disabled="pending || hasDraftComments || hasActualComments" @click="onToApprove">Согласовать</VBtn>
              <VBtn color="primary" class="mr-2" depressed :disabled="pending || hasActualComments || !hasDraftComments" @click="onWorkshopRework">На доработку</VBtn>
              <VBtn color="error" class="mr-2" depressed :disabled="pending" @click="onFinishByWorkshopReviewer">Завершить заявку</VBtn>
            </template>
          </template>
          <template v-if="isReviewer">
            <template v-if="isRevokeRequest || isSlaveRevokeRequest">
              <VBtn color="success" class="mr-2" depressed :disabled="pending" @click="onApproveRevoke">Согласовать отзыв исполнителя</VBtn>
              <VBtn color="primary" class="mr-2" depressed :disabled="pending" @click="onRejectRevoke">Отказать в отзыве исполнителя</VBtn>
            </template>
            <template v-else-if="isApproving || isAwaitReplacementInspector">
              <VBtn color="success" class="mr-2" depressed :disabled="pending || hasDraftComments || hasActualComments" @click="onApprove">Согласовать</VBtn>
              <VBtn color="primary" class="mr-2" depressed :disabled="pending || hasActualComments || !hasDraftComments" @click="onRework">На доработку</VBtn>
              <VBtn color="error" class="mr-2" depressed :disabled="pending" @click="onFinishByReviewer">Завершить заявку</VBtn>
            </template>
          </template>
          <template v-if="isInspector && !isRevokeRequest && !isSlaveRevokeRequest && !isFinish">
            <VBtn v-if="(isApproved || isProlongation) && !authorAsInspector" color="primary" class="mr-2" depressed :disabled="pending" @click="onFinishRequest">Запрос на закрытие</VBtn>
            <template v-if="!(isApproved || isProlongation || isFinishRequest)">
              <VBtn v-if="inspectorDecision" color="info" outlined :class="$style.label">{{inspectorDecision}}</VBtn>
              <InspectorDecision v-else @submit="onDecision" />
            </template>
          </template>
          <template v-if="((isInspector && !isRevokeRequest && !isSlaveRevokeRequest) || isInitiator) && (isApproved || isFinishRequest || isProlongation || isFinish) && !authorAsInspector">
            <VBtn color="primary" class="mr-2" depressed :disabled="pending" @click="toChat">
              <template v-if="isInspector">Чат с заявителем</template>
              <template v-else>Чат с инспектором</template>
              <template v-if="count">{{' '}}({{count}})</template>
            </VBtn>
          </template>
        </template>
      </template>
      <template v-if="canDelegateInspector">
        <InspectorDelegate :value="selectedInspectorId" @submit="onInspectorDelegate" />
      </template>
    </div>
  </VFooter>
</div>
</template>

<script>
import {
  concat,
  filter,
  find,
  get,
  groupBy,
  has,
  head,
  includes, indexOf,
  isArray,
  isEmpty, join,
  keys,
  map,
  mapValues,
  reduce,
  toNumber,
  toString
} from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import SectionCard from '@/components/inspect/SectionCard/SectionCard';
import StaticField from '@/components/general/StaticField/StaticField';
import DateInput from '@/components/general/DateInput/DateInput';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
import CommentBtn from '@/components/inspect/ApplicationDetail/components/CommentBtn';
import RemoveBtn from '@/components/inspect/ApplicationDetail/components/RemoveBtn';
import CopyBtn from '@/components/inspect/ApplicationDetail/components/CopyBtn';
import InspectorDecision from '@/components/inspect/ApplicationDetail/components/InspectorDecision';
import ApplicationValues from '@/components/inspect/ApplicationDetail/components/ApplicationValues';
import ApplicationProlog from '@/components/inspect/ApplicationDetail/components/ApplicationProlog';
import ApplicationFinished from '@/components/inspect/ApplicationDetail/components/ApplicationFinished';
import CancelInvite from '@/components/inspect/ApplicationDetail/components/CancelInvite';
import InspectorRevoke from '@/components/inspect/ApplicationDetail/components/InspectorRevoke';
import { APPLICATION_ACTIONS, INSPECTOR_STATUS, APPLICATION_STATUSES, USER_TYPES } from '@/store/inspect/enums';
import InspectorDelegate from './components/InspectorDelegate.vue';

export default {
  name: 'ApplicationDetail',
  components: {
    InspectorDelegate,
    InspectorRevoke,
    ApplicationFinished,
    CopyBtn,
    RemoveBtn,
    CommentBtn,
    SectionCard,
    StaticField,
    DateInput,
    InspectorDecision,
    FileFieldMultiple,
    ApplicationValues,
    ApplicationProlog,
    CancelInvite,
  },
  props: {
    id: { type: String, required: true },
    state: { type: String, required: true },
    count: { type: Number },
    values: { type: Object },
    info: { type: Object },
    inspectorSlavesCount: { type: Number },
  },
  data() {
    return {
      timer: null,
      inspectors: [],
      valid: true,
      fields: JSON.parse(JSON.stringify(this.values)),
      rules: {
        required: [
          v => {
            if (isArray(v) && isEmpty(v)) return 'Обязательное поле';
            if (!v) return 'Обязательное поле';
            return true;
          }
        ]
      },
    }
  },
  computed: {
    ...mapGetters({
      pending: 'inspect/pending',
      comments: 'inspect/comments',
      hasRole: 'user/hasRole',
      user: 'user/current',
    }),
    company() {
      return get(this.fields, ['company', 'values', 0]);
    },
    workshop() {
      return get(this.fields, ['workshop', 'values', 0]);
    },
    options() {
      return {
        workshop: this.workshopOptions,
        reviewer: this.reviewerOptions,
        workshopReviewer: this.workshopReviewerOptions,
        costItem: this.costItemOptions,
      }
    },
    workshopOptions() {
      const company = get(this.fields, ['company', 'values', '0']);
      const options = get(this.fields, ['workshop', 'options']);
      const mapper = groupBy(options, 'company');
      return concat(get(mapper, company, []), get(mapper, '', []));
    },
    workshopReviewerOptions() {
      const company = get(this.fields, ['company', 'values', '0']);
      const options = get(this.fields, ['workshopReviewer', 'options']);
      return get(groupBy(options, 'company'), company, options);
    },
    reviewerOptions() {
      const company = get(this.fields, ['company', 'values', '0']);
      const options = get(this.fields, ['reviewer', 'options']);
      return get(groupBy(options, 'company'), company, options);
    },
    costItemOptions() {
      const company = get(this.fields, ['company', 'values', '0']);
      const options = get(this.fields, ['costItem', 'options']);
      return get(groupBy(options, 'company'), company, []);
    },
    commentByKey() {
      return groupBy(get(this.comments, 'items'), 'code');
    },
    commentsByKeyCounter() {
      return mapValues(this.commentByKey, (items) => ({
        actual: filter(items, { actual: true }).length,
        draft: filter(items, { draft: true }).length,
        total: items.length,
      }))
    },
    inspectorOptions() {
      return map(get(this.values, 'inspector.options', []), ({ status, ...props}) => ({
        ...props,
        status: get(INSPECTOR_STATUS, [status, 'text'],  status),
        statusCode: get(INSPECTOR_STATUS, [status, 'value']),
        name: join([props.name, props.userName], ' '),
      }))
    },
    isApproving() {
      return get(APPLICATION_STATUSES, 'APPROVING.value') === this.state;
    },
    isApprovingWorkshop() {
      return get(APPLICATION_STATUSES, 'APPROVING_WORKSHOP.value') === this.state;
    },
    isApproved() {
      return get(APPLICATION_STATUSES, 'APPROVED.value') === this.state;
    },
    isProlongation() {
      return get(APPLICATION_STATUSES, 'PROLONGATION.value') === this.state;
    },
    isFinishRequest() {
      return get(APPLICATION_STATUSES, 'FINISH_REQUEST.value') === this.state;
    },
    isFinish() {
      return get(APPLICATION_STATUSES, 'FINISH.value') === this.state;
    },
    isFinishByReviewer() {
      return get(APPLICATION_STATUSES, 'FINISHED_BY_REVIEWER.value') === this.state;
    },
    isFinishByInitiator() {
      return get(APPLICATION_STATUSES, 'FINISHED_BY_INITIATOR.value') === this.state;
    },
    isFinishByInspectorFailed() {
      return get(APPLICATION_STATUSES, 'FINISHED_BY_INSPECTOR_FAILED.value') === this.state;
    },
    isWorkshopFinish() {
      return get(APPLICATION_STATUSES, 'FINISH_WORKSHOP.value') === this.state;
    },
    isAwaitReplacementInspector() {
      return !!~indexOf([get(APPLICATION_STATUSES, 'AWAIT_REPLACEMENT_INSPECTOR.value'), get(APPLICATION_STATUSES, 'AWAIT_REPLACEMENT_SLAVE_INSPECTOR.value')], this.state);
    },
    isAwaitReplacementSlaveInspector() {
      return this.state === get(APPLICATION_STATUSES, 'AWAIT_REPLACEMENT_SLAVE_INSPECTOR.value');
    },
    isRework() {
      return get(APPLICATION_STATUSES, 'REWORK.value') === this.state;
    },
    isWorkshopRework() {
      return get(APPLICATION_STATUSES, 'REWORK_WORKSHOP.value') === this.state;
    },
    isDraft() {
      return get(APPLICATION_STATUSES, 'DRAFT.value') === this.state;
    },
    isReviewer() {
      return this.hasRole([get(USER_TYPES, 'REVIEWER.value', 'REVIEWER')], 'inspect');
    },
    isWorkshopReviewer() {
      return this.hasRole([get(USER_TYPES, 'WORKSHOP_REVIEWER.value', 'WORKSHOP_REVIEWER')], 'inspect');
    },
    isInitiator() {
      return this.hasRole([get(USER_TYPES, 'INITIATOR.value', 'INITIATOR')], 'inspect');
    },
    isInspector() {
      return this.hasRole([get(USER_TYPES, 'INSPECTOR.value', 'INSPECTOR')], 'inspect') || toString(get(this.values, 'inspector.values.0')) === toString(get(this.user, 'id'));
    },
    isMasterInspector() {
      return this.isInspector && !toString(get(this.user, 'masterUser.id'));
    },
    isRevokeRequest() {
      return get(APPLICATION_STATUSES, 'REQUESTED_TO_REVOKE_INSPECTOR.value') === this.state;
    },
    isSlaveRevokeRequest() {
      return get(APPLICATION_STATUSES, 'REQUESTED_TO_REVOKE_SLAVE_INSPECTOR.value') === this.state;
    },
    isMyApplication() {
      const id = toString(get(this, 'user.id'));
      if (!id) return false;
      if (this.isInitiator && id === toString(get(this, 'info.authorId'))) return true;
      if (this.isReviewer && id === toString(head(get(this, 'values.reviewer.values')))) return true;
      if (this.isWorkshopReviewer && id === toString(head(get(this, 'values.workshopReviewer.values')))) return true;
      if (this.isInspector && !!find(get(this.values, 'inspector.options'), ({ value }) => toString(value) === id)) {
        if (this.selectedInspectorId.length && this.selectedInspectorId === id) return true;
        if (!this.selectedInspectorId.length) return true;
      }
      return false;
    },
    selectedInspectorId() {
      return toString(get(this.values, 'inspector.values.0'));
    },
    authorAsInspector() {
     return toString(get(this.values, 'inspector.values.0')) === toString(get(this, 'info.authorId'));
    },
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
    hasDraftComments() {
      return !!find(this.commentsByKeyCounter, ({ draft }) => !!draft);
    },
    hasActualComments() {
      return !!find(this.commentsByKeyCounter, ({ actual }) => !!actual);
    },
    showInspectionTypeAnotherComment() {
      return !!~get(this.fields, ['inspectionTypes', 'values'], []).indexOf('other');
    },
    showProductCategoryAnotherComment() {
      return !!~get(this.fields, ['productCategory', 'values'], []).indexOf('other');
    },
    showWorkshopAnotherComment() {
      return !!~get(this.fields, ['workshop', 'values'], []).indexOf('other');
    },
    showProducts() {
      return !~this.fields['inspectionTypes']['values'].indexOf('audit_organizatsii_predpriyatiya_izgotovitelya');
    },
    isInternalInspection() {
      return !!get(this.fields, ['isInternalInspection', 'values', 0], false);
    },
    inspectorDecision() {
      const decision = find(get(this.values, ['inspector', 'options']), { id: toNumber(this.user.id) });
      if (!decision) return null;
      if (decision.status === INSPECTOR_STATUS.AGREED.value) return 'Вы дали согласие на участие';
      if (decision.status === INSPECTOR_STATUS.DELEGATED.value) return 'Вы дали согласие на участие';
      if (decision.status === INSPECTOR_STATUS.REJECTED.value) return 'Вы отказались от участия';
      return null;
    },
    canDelegateInspector() {
      const canByStatus = this.isApproved || this.isProlongation;
      const canByRole = !this.isReviewer && !this.isWorkshopReviewer;
      const isSelectedSlaveForCurrent = find(this.inspectorOptions, { id: toNumber(this.selectedInspectorId) });
      return canByRole && this.inspectorDecision && this.selectedInspectorId && (this.isMasterInspector && isSelectedSlaveForCurrent) && canByStatus
    }
  },
  methods: {
    has,
    includes,
    ...mapActions({
      fetchCommentList: 'inspect/fetchCommentList',
      updateApplication: 'inspect/updateApplication',
      approveApplication: 'inspect/approveApplication',
      fetchApplicationDetail: 'inspect/fetchApplicationDetail',
      sendInviteInspector: 'inspect/sendInviteInspector',
      cancelInviteInspector: 'inspect/cancelInviteInspector',
      decisionInspector: 'inspect/decisionInspector',
      delegateInspector: 'inspect/delegateInspector',
      finishApplication: 'inspect/finishApplication',
      deleteApplication: 'inspect/deleteApplication',
    }),
    getClassByValue(key) {
      if (get(this.commentsByKeyCounter, [key, 'actual'], 0)) return 'error lighten-4';
      if (get(this.commentsByKeyCounter, [key, 'draft'], 0)) return 'orange lighten-4';
      return '';
    },
    getInspectorClassByRow({ id }) {
      if (id === this.fields['inspector']['values'][0]) return 'success lighten-3 font-weight-bold';
    },
    showCommentBtn(key) {
      if (!this.isMyApplication) return false;
      if (this.isInspector) return false;
      const comments = get(this.commentsByKeyCounter, [key, 'total'], 0);
      return !this.isDraft || !!comments;
    },
    hiddenForRole(arr) {
      const fieldKeys = keys(this.fields);
      return reduce(arr, (acc, cur) => {
        if (!~fieldKeys.indexOf(cur)) return acc;
        if (this.isInspector) {
          if (cur === 'financeSource') return acc;
        }
        acc.push(cur);
        return acc;
      }, []);
    },
    toComment: function(code) {
      const application = this.id;
      this.$router.push({ name: 'inspect/ApplicationCommentsView', params: { application, code }})
    },
    toChat: function() {
      const application = this.id;
      this.$router.push({ name: 'inspect/AppReportCommentsView', params: { application }})
    },
    onApprove: function() {
      const id = this.id;
      const action = get(APPLICATION_ACTIONS, 'APPROVE.value', 'APPROVE');
      if (this.$refs.form.validate()) this.approveApplication({ id, action }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно согласована',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onWorkshopApprove: function() {
      const id = this.id;
      const action = get(APPLICATION_ACTIONS, 'TO_WORKSHOP_REVIEWER_APPROVE.value', 'TO_WORKSHOP_REVIEWER_APPROVE');
      if (this.$refs.form.validate()) this.approveApplication({ id, action }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно отправлена на согласование',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onToApprove: function() {
      const id = this.id;
      const next = this.isInternalInspection ? 'APPROVE': 'TO_APPROVE';
      const action = get(APPLICATION_ACTIONS, [next, 'value'], next);
      if (this.$refs.form.validate()) this.approveApplication({ id, action }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: this.isInternalInspection ? 'Заявка успешно согласована': 'Заявка успешно отправлена на согласование',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onFinishRequest: function() {
      const id = this.id;
      const action = get(APPLICATION_ACTIONS, 'REQUEST_TO_FINISH.value', 'REQUEST_TO_FINISH');
      this.approveApplication({ id, action }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно отправлена на закрытие',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onRework: function() {
      const id = this.id;
      const action = get(APPLICATION_ACTIONS, 'TO_REWORK.value', 'TO_REWORK');
      if (this.$refs.form.validate()) this.approveApplication({ id, action }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка отправлена на доработку',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onRevoke: function ({ comment, name, action, files }) {
      const id = this.id;
      this.approveApplication({ id, comment, name, action, files }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Отправлен запрос на отзыв исполнителя',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onApproveRevoke: function () {
      const id = this.id;
      const action = this.isRevokeRequest ? get(APPLICATION_ACTIONS, 'APPROVE_TO_REVOKE_INSPECTOR.value', 'APPROVE_TO_REVOKE_INSPECTOR') : get(APPLICATION_ACTIONS, 'APPROVE_TO_REVOKE_SLAVE_INSPECTOR.value', 'APPROVE_TO_REVOKE_INSPECTOR');
      if (this.$refs.form.validate()) this.approveApplication({ id, action }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Отзыв инспектора согласован',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onRejectRevoke: function () {
      const id = this.id;
      const action = this.isRevokeRequest ? get(APPLICATION_ACTIONS, 'REJECT_TO_REVOKE_INSPECTOR.value', 'REJECT_TO_REVOKE_INSPECTOR') : get(APPLICATION_ACTIONS, 'REJECT_TO_REVOKE_SLAVE_INSPECTOR.value', 'REJECT_TO_REVOKE_INSPECTOR');
      if (this.$refs.form.validate()) this.approveApplication({ id, action }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Отзыв инспектора не согласован',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onWorkshopRework: function() {
      const id = this.id;
      const action = get(APPLICATION_ACTIONS, 'TO_WORKSHOP_REVIEWER_REWORK.value', 'TO_WORKSHOP_REVIEWER_REWORK');
      if (this.$refs.form.validate()) this.approveApplication({ id, action }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка отправлена на доработку',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onFinishByReviewer: function() {
      const id = this.id;
      const action = get(APPLICATION_ACTIONS, 'FINISH_BY_REVIEWER.value', 'FINISH_BY_REVIEWER');
      if (this.$refs.form.validate()) {
        this.$swal("Вы уверены? Действие нельзя будет отменить.").then(({ isConfirmed }) => {
          if (isConfirmed) {
            this.approveApplication({ id, action }).then(() => {
              this.$notify({
                type: 'success',
                title: 'Успех',
                text: 'Заявка закрыта',
              });
              return this.fetchApplicationDetail({ id });
            });
          }
        });
      }
    },
    onFinishByWorkshopReviewer: function() {
      const id = this.id;
      const action = get(APPLICATION_ACTIONS, 'FINISHED_BY_WORKSHOP_REVIEWER.value', 'FINISHED_BY_WORKSHOP_REVIEWER');
      if (this.$refs.form.validate()) {
        this.$swal("Вы уверены? Действие нельзя будет отменить.").then(({ isConfirmed }) => {
          if (isConfirmed) {
            this.approveApplication({ id, action }).then(() => {
              this.$notify({
                type: 'success',
                title: 'Успех',
                text: 'Заявка закрыта',
              });
              return this.fetchApplicationDetail({ id });
            });
          }
        })
      }
    },
    onProlong: function({ comment }) {
      const id = this.id;
      const action = get(APPLICATION_ACTIONS, 'PROLONG.value', 'PROLONG');
      this.approveApplication({ id, action, comment }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка возвращена на доработку',
        });
        return this.fetchApplicationDetail({ id });
      });
    },
    onDelete: function() {
      const id = this.id;
      this.$swal("Вы уверены что хотите удалить заявку? Действие нельзя будет отменить.").then(({ isConfirmed }) => {
        if (isConfirmed) {
          if (id) {
            this.deleteApplication({ id }).then(() => {
              this.$notify({
                type: 'success',
                title: 'Успех',
                text: 'Заявка удалена',
              });
              this.$router.push({ name: 'inspect/ApplicationListView' });
            });
          }
        }
      });
    },
    onFinish: function({ comment, rating, name, fail }) {
      const id = this.id;
      this.$swal("Вы уверены? Действие нельзя будет отменить.").then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.finishApplication({ id, comment, rating, name, fail }).then(() => {
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Заявка закрыта',
            });
            return this.fetchApplicationDetail({ id });
          });
        }
      });
    },
    onSendInvite: function() {
      const application = this.id;
      const inspectors = map(this.inspectors, 'id');
      this.sendInviteInspector({ application, inspectors }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Сообщения успешно отправлены',
        });
        return this.fetchApplicationDetail({ id: application });
      })
    },
    onCancelInvite: function ({ comment, inspectors }) {
      const application = this.id;
      this.cancelInviteInspector({ application, inspectors, comment }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Приглашения успешно отозваны',
        });
        return this.fetchApplicationDetail({ id: application });
      })
    },
    onDecision: function({ decision, comment, inspector }) {
      const application = this.id;
      this.decisionInspector({ application, decision, comment, inspector }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Решение отправлено',
        });
        return this.fetchApplicationDetail({ id: application });
      })
    },
    onInspectorDelegate: function({ comment, inspector }) {
      const application = this.id;
      this.delegateInspector({ application, comment, inspector }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Исполнитель изменен',
        });
        return this.fetchApplicationDetail({ id: application });
      })
      return;
    },
    onSave: function() {
      const id = this.id;
      const fields = this.fields;
      this.updateApplication({ id, fields }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно сохранена',
        });
        return Promise.all([
          this.fetchCommentList({ id }),
          this.fetchApplicationDetail({ id }),
        ]);
      });
    },
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
  },
  watch: {
    diff: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.timer = setInterval(this.onSave, 5 * 60 * 1000) // 5 мин
        } else {
          clearInterval(this.timer);
        }
        this.$route.meta.beforeEach = (to, from, next) => {
          if (from.name === 'inspect/CounterpartyDetailView' && this.diff) {
            this.$notify({
              type: 'warning',
              title: 'Подождите',
              text: 'Идет сохранение',
            });
            this.onSave();
          } else {
            clearInterval(this.timer);
            next();
          }
        };
      }
    },
    id: {
      immediate: true,
      handler: function() {
        const id = this.id;
        this.fetchCommentList({ id });
      }
    },
    values: {
      handler: function() {
        this.onReset();
      }
    },
  }
}
</script>

<style module lang="scss">
.root {

}
.item {
  padding: 20px 16px;
  margin: 0 -16px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}
.value {
  padding: 10px 16px;
  margin: 0 -16px;
}
.title {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
.progress {
  top: 0;
}
.product {
  padding: 10px 16px;
  margin: 0 -16px;
  .left {
    float: left;
    padding-right: 10px;
    .index {
      font-weight: bold;
      padding: 9px 0;
    }
  }
  .right { float: right }
  &:first-child .right { margin-top: 22px; }
}
.control {
  float: right;
  min-height: 40px;
  margin-left: 10px;
  position: relative;
  z-index: 1;
}
.label {
  pointer-events: none;
}
</style>
