<template>
  <VDialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <VBtn color="primary" class="mr-2" depressed v-on="on">Продлить заявку</VBtn>
    </template>
    <VCard tile>
      <VToolbar flat dark color="primary">
        <VToolbarTitle class="px-2">Укажите причину продления</VToolbarTitle>
        <VSpacer/>
        <VBtn icon dark @click="dialog = false">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VCardText class="pt-5">
        <CommentForm @submit="onSubmit" />
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import CommentForm from '@/components/general/CommentForm/CommentForm';
export default {
  name: 'ApplicationProlog',
  components: {
    CommentForm,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    onSubmit(comment) {
      this.dialog = false;
      this.$emit('submit', { comment });
    }
  }
}
</script>

<style module lang="scss">

</style>
