<template>
  <div :class="[$style.root, { [$style.disabled]: readonly }]" @click="$emit('click')">
    <div :class="$style.icon" class="text-decoration-none">
      <VImg v-if="src" :lazySrc="src" height="30" width="30" aspect-ratio="1" />
      <VIcon v-else size="32" v-text="icon" />
    </div>
    <a v-if="url" :class="$style.link" :href="url" target="_blank" />
    <div :class="$style.inner">
      <slot />
      <div :class="$style.name">
        <slot name="name">{{ name }}</slot>
        <fieldset :class="$style.border">
          <legend>
            <span class="notranslate" />
          </legend>
        </fieldset>
      </div>
    </div>
    <VIcon v-if="url && !readonly" :class="$style.control" @click.stop="$emit('delete')" color="error">mdi-close</VIcon>
  </div>
</template>

<script>
export default {
  name: 'FileIcon',
  props: {
    src: { type: String },
    url: { type: String },
    name: { type: String },
    icon: { type: String, default: 'mdi-file-outline'},
    readonly: { type: Boolean, default: false },
  },
}
</script>

<style module lang="scss">
.root{
  display: flex;
  align-items: center;
  padding: 6px;
  position: relative;
  cursor: pointer;
  height: 40px;
  box-sizing: border-box;

  .border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.38);
    pointer-events: none;
    @at-root .root.disabled .border {
      border: 1px dashed rgba(0, 0, 0, 0.1);
    }
  }
  .icon {
    margin-right: 8px;
  }
  .inner {
    flex: 1 1 auto;
    max-width: calc(100% - 60px);
    line-height: 1;
    text-overflow: ellipsis;
  }
  .name {
    display: block;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:not(:first-child) {
      margin-top: 14px;
      .border {
        legend {
          width: 76px;
          margin-left: 45px;
        }
      }
    }
  }
  .control {
    position: relative;
    flex: 0 0 20px;
    pointer-events: auto;
  }
  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
