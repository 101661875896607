<template>
  <VDialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <VBtn color="success" class="mr-2" depressed v-on="on">Закрыть заявку</VBtn>
    </template>
    <VCard tile>
      <VToolbar flat dark color="primary">
        <VToolbarTitle class="px-2">
          <slot name="title">Оставьте отзыв и оценку работе Инспектора по текущей заявке</slot>
        </VToolbarTitle>
        <VSpacer/>
        <VBtn icon dark @click="onClose">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VCardText class="pt-5">
        <FinishForm :hiddenRating="hiddenRating" @submit="onSubmit" />
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import FinishForm from '@/components/inspect/ApplicationDetail/components/FinishForm';
export default {
  name: 'ApplicationFinished',
  components: {
    FinishForm,
  },
  props: {
    hiddenRating: { type: Boolean },
  },
  data() {
    return {
      dialog: false,
      decision: null,
    }
  },
  methods: {
    onOpen(decision) {
      this.dialog = true;
      this.decision = decision;
    },
    onClose() {
      this.dialog = null;
    },
    onSubmit({ name, comment, rating, fail }) {
      this.dialog = false;
      const decision = this.decision;
      this.$emit('submit', { decision, comment, name, rating, fail });
    }
  }
}
</script>

<style module lang="scss">

</style>
