<template>
  <VDialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on }">
      <VBtn color="primary" class="mr-2" v-on="on" depressed @click="onOpen">Отозвать приглашение</VBtn>
    </template>
    <VCard tile>
      <VToolbar flat dark color="primary">
        <VToolbarTitle class="px-2">
          Вы уверены, что хотите отозвать приглашение инспекционной компании "<slot />"
        </VToolbarTitle>
        <VSpacer/>
        <VBtn icon dark @click="onClose">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </VToolbar>
      <VCardText class="pt-5">
        <VForm @submit.prevent="onSubmit">
          <VTextarea v-model="message" label="Комментарий" rows="3" outlined auto-grow hide-details />
          <div class="mt-3">
            <VBtn color="primary" depressed class="mr-3" :disabled="!message" @click="onSubmit">Да</VBtn>
            <VBtn color="secondary" depressed class="mr-3" @click="onClose">Нет</VBtn>
          </div>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: 'CancelInvite',
  data() {
    return {
      dialog: false,
      message: '',
    }
  },
  methods: {
    onOpen() {
      this.dialog = true;
    },
    onClose() {
      this.dialog = null;
      this.message = '';
    },
    onSubmit() {
      this.dialog = false;
      const comment = this.message;
      this.$emit('submit', { comment });
      this.message = '';
    }
  }
}
</script>

<style module lang="scss">

</style>
